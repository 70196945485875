<template>
  <a-input-search
    v-model:value="searchValue"
    style="margin-bottom: 8px"
    placeholder="Search"
  />
  <a-tree
    checkable
    :tree-data="treeData"
    v-model:expandedKeys="expandedKeys"
    v-model:checkedKeys="checkedKeys"
    :autoExpandParent="autoExpandParent"
    @expand="onExpand"
  >
    <template #title="{ title }">
      <span v-if="title.indexOf(searchValue) > -1">
        {{ title.substr(0, title.indexOf(searchValue)) }}
        <span style="color: #f50">{{ searchValue }}</span>
        {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
      </span>
      <span v-else>{{ title }}</span>
    </template>
  </a-tree>
</template>

<script>
  import { computed, ref, watch } from 'vue'
  import { useStore } from 'vuex'

  const getParentKey = (key, tree) => {
    let parentKey

    for (let i = 0; i < tree.length; i++) {
      const node = tree[i]

      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children)
        }
      }
    }

    return parentKey
  }

  export default {
    setup() {
      const store = useStore()
      const storeTree = store.getters['store/storeTree']
      // 取得所有節點的key與title(供後續搜尋用)
      const storeTreeGenerateList = store.getters['store/storeTreeGenerateList']
      const expandedKeys = ref(['0'])
      const checkedKeys = ref([])
      const checkedListTree = computed(
        () => store.getters['store/checkedListTree']
      )
      const autoExpandParent = ref(false)
      const treeData = ref(storeTree)
      const searchValue = ref('')

      const onExpand = (keys) => {
        expandedKeys.value = keys
        autoExpandParent.value = false
      }

      watch(checkedKeys, () => {
        store.dispatch('store/setCheckedListTree', checkedKeys.value)
      })
      watch(checkedListTree, () => {
        checkedKeys.value = checkedListTree.value
      })

      // 搜尋
      watch(searchValue, (value) => {
        const expanded = storeTreeGenerateList
          .map((item) => {
            if (item.title.indexOf(value) > -1) {
              return getParentKey(item.key, treeData.value)
            }

            return null
          })
          .filter((item, i, self) => item && self.indexOf(item) === i)
        expandedKeys.value = expanded
        searchValue.value = value
        autoExpandParent.value = true
      })
      return {
        searchValue,
        treeData,
        expandedKeys,
        checkedKeys,
        autoExpandParent,
        onExpand,
      }
    },
  }
</script>
<style></style>
