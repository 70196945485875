import { render } from "./StoreTags.vue?vue&type=template&id=23d2639a"
import script from "./StoreTags.vue?vue&type=script&lang=js"
export * from "./StoreTags.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "23d2639a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('23d2639a', script)) {
    api.reload('23d2639a', script)
  }
  
  module.hot.accept("./StoreTags.vue?vue&type=template&id=23d2639a", () => {
    api.rerender('23d2639a', render)
  })

}

script.__file = "src/components/StoreTags.vue"

export default script