<template>
  <a-tag
    closable
    color="#FAAD14"
    v-for="item in tags.level0"
    :key="item.key"
    @close="delTag(item.key)"
  >
    {{ item.title }}
  </a-tag>
  <a-tag
    closable
    color="#FA8C16"
    v-for="item in tags.level1"
    :key="item.key"
    @close="delTag(item.key)"
  >
    {{ item.title }}
  </a-tag>
  <a-tag
    closable
    color="#1890FF"
    v-for="item in tags.level2"
    :key="item.key"
    @close="delTag(item.key)"
  >
    {{ item.title }}
  </a-tag>
  <a-tag
    closable
    color="#52C41A"
    v-for="item in tags.level3"
    :key="item.key"
    @close="delTag(item.key)"
  >
    {{ item.title }}
  </a-tag>
</template>

<script>
  import { computed, watch, ref } from 'vue'
  import { useStore } from 'vuex'
  export default {
    setup() {
      const store = useStore()
      const storeChecked = computed(
        () => store.getters['store/checkedListTree']
      )
      const storeTreeGenerateList = store.getters['store/storeTreeGenerateList']
      const tags = ref({
        level1: [],
        level2: [],
        level3: [],
        level4: [],
      })
      const calcTags = () => {
        let unTags = [...storeChecked.value]
        tags.value = {
          level0: [],
          level1: [],
          level2: [],
          level3: [],
        }
        let i = 0
        while (unTags.length > 0 && i < 200) {
          const idx = unTags.shift()
          const idxInfo = storeTreeGenerateList.find((item) => item.key == idx)
          const level = (idx.match(/-/g) || []).length
          tags.value[`level${level}`].push({
            key: idx,
            title: idxInfo.title,
          })

          unTags = unTags.filter((item) => item.indexOf(`${idx}-`) != 0)
          i++
        }
      }

      const delTag = (key) => {
        store.dispatch('store/removeChecked', key)
      }

      watch(storeChecked, () => {
        calcTags()
      })
      return { storeChecked, tags, delTag }
    },
  }
</script>

<style></style>
